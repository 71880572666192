import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import HomeHero from '../components/home-hero'
import Spread from '../components/spread'
import Strapline from '../components/strapline'
import AppPromo from '../components/app-promo'
import JoinUs from '../components/join-us'
import Cluster from '../components/cluster'
import Gallery from '../components/gallery'
import ArticleImage from '../components/article-image'
import LiveGenLink from '../components/live-gen-link'

import { Experiment, Variant } from '../ab-test'
import GeneralContent from '../components/general-content'

export const query = graphql`
  query Home(
    $pageId: String!
    $relatedPages: Boolean!
    $relatedFilter: ContentfulPageFilterInput
    $relatedLimit: Int
  ) {
    home: contentfulIndex(id: { eq: $pageId }) {
      title
      description
      sections {
        __typename
        ... on Node {
          ... on ContentfulHomeHero {
            heading
            subheading
            callToAction
          }
          ... on ContentfulStrapline {
            strapline
          }
          ... on ContentfulLink {
            title
            url
          }
          ... on ContentfulItem {
            ...Item
          }
          ... on ContentfulMobileAppPromo {
            title
            body {
              raw
              references {
                ...References
              }
            }
            aside {
              raw
              references {
                ...References
              }
            }
            appScreenshotImage {
              gatsbyImageData(quality: 60, layout: FULL_WIDTH)
            }
          }
          ... on ContentfulGeneralContent {
            title
            type
            description {
              raw
            }
            ctaText
            ctaUrl
            ctaOpenInNewTab
          }
          ... on ContentfulEventGallery {
            title
            body {
              raw
              references {
                ...References
              }
            }
            events {
              title
              date(formatString: "YYYY")
              image {
                title
                description
                gatsbyImageData(
                  quality: 60
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  height: 300
                  width: 500
                  formats: [WEBP]
                )
              }
              body {
                raw
              }
            }
          }
          ... on ContentfulRelatedPages {
            __typename
            title
            pages {
              ...RelatedPage
            }
          }
          ... on ContentfulJoinUs {
            heading
            callToAction
          }
        }
      }
    }
    related: allContentfulPage(
      filter: $relatedFilter
      sort: { createdAtTimestamp: DESC }
      limit: $relatedLimit
    ) @include(if: $relatedPages) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const Home: React.FC<PageProps<GatsbyTypes.HomeQuery>> = ({
  data: { home = {}, related = {} },
}) => {
  const sections = home.sections
    ?.map((section, i) => {
      switch (section?.__typename) {
        case 'ContentfulHomeHero':
          return (
            <HomeHero
              heading={section.heading as string}
              subheading={section.subheading}
              callToAction={section.callToAction}
            />
          )

        case 'ContentfulStrapline':
          return <Strapline strapline={section.strapline as string} />

        case 'ContentfulLink':
          return <LiveGenLink title={section.title} to={section.url} />

        case 'ContentfulItem':
          return (
            <Spread
              title={section.title}
              items={[
                {
                  body: section.body,
                  image: section.image,
                  video: section.video,
                },
              ]}
              flip={Boolean(i & 1)}
              variant="index"
            />
          )

        case 'ContentfulMobileAppPromo':
          return (
            <AppPromo
              title={section.title}
              body={section.body}
              aside={section.aside}
              screenshot={section.appScreenshotImage}
            />
          )

        case 'ContentfulGeneralContent':
          return <GeneralContent {...section} />

        case 'ContentfulEventGallery':
          return (
            <Gallery
              title={section.title}
              body={section.body}
              items={section.events?.map(
                (event, i) =>
                  event?.image && (
                    <ArticleImage
                      key={i}
                      fluid={event.image.gatsbyImageData}
                      alt={event.image.title as string}
                      description={event.image.description as string}
                      link={`/our-story/25-years-of-ecotricity/#${event.date}`}
                    />
                  )
              )}
            />
          )

        case 'ContentfulRelatedPages': {
          const pinned = section.pages || []
          const nodes = related.nodes || []
          return (
            <Cluster
              title={section.title}
              items={[...pinned, ...nodes].map(page => ({
                title: page?.title,
                image: page?.image,
                text: page?.description,
                page: page,
              }))}
              variant="index"
            />
          )
        }

        case 'ContentfulJoinUs':
          return (
            <JoinUs
              heading={section.heading}
              callToAction={section.callToAction}
            />
          )

        default:
          return null
      }
    })
    .map((section, i) => {
      switch (home.sections && home.sections[i]?.__typename) {
        case 'ContentfulHomeHero':
          return (
            <Section as="div" key={i} pos={i} fill="homeHero" variant="wide">
              {section}
            </Section>
          )

        case 'ContentfulStrapline':
          return (
            <Section as="div" key={i} pos={i} fill="spraySW" variant="wide">
              {section}
            </Section>
          )

        case 'ContentfulLink':
          return (
            <Section key={i} pos={i} fill="liveGenLink" variant="wide">
              {section}
            </Section>
          )

        case 'ContentfulGeneralContent': {
          const type = home.sections[i].type[0]

          if (type === 'Trustpilot Promo') {
            return (
              <Section key={i} pos={i} fill="cutNWSpraySE" variant="index">
                {section}
              </Section>
            )
          }

          if (
            type === 'Three Point Banner' ||
            type === 'Three Point Banner Wide'
          ) {
            return (
              <Experiment name={`${type.split(' ').join('-').toLowerCase()}`}>
                <Variant default={true}>
                  <></>
                </Variant>
                <Variant>
                  <Section key={i} pos={i} fill="cutNWSpraySE" variant="index">
                    {section}
                  </Section>
                </Variant>
              </Experiment>
            )
          }

          if (type === 'Find Carbon Footprint') {
            return process.env.GATSBY_ENABLE_CARBON_CALCULATOR_V2 === 'true' ? (
              <Section
                key={i}
                pos={i}
                fill="flippedCutNWSpraySE"
                variant="index"
              >
                {section}
              </Section>
            ) : null
          }

          return null
        }
        default:
          return (
            <Section key={i} pos={i} variant="index">
              {section}
            </Section>
          )
      }
    })

  return <Layout page={home}>{sections}</Layout>
}

export default Home
