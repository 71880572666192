import React from 'react'
import { Flex, ThemeUIStyleObject, Box } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import HomeHeroText from './home-hero-text'
import HomeHeroWidget from './home-hero-widget'
import { fluidScale } from '../utils/mixins'

interface Props {
  heading: string
  subheading?: string
  callToAction?: string
}

const HomeHero: React.FC<Props> = ({ heading, subheading, callToAction }) => {
  const data = useStaticQuery(graphql`
    query {
      ecoFlag: file(relativePath: { eq: "images/home-hero.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      whichLogos: contentfulAsset(title: { eq: "Landing page Which? logos" }) {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
  `)

  console.log('data', heading, subheading, callToAction, data)

  //Styles
  const wrapper: ThemeUIStyleObject = {
    alignItems: 'center',
  }

  const text: ThemeUIStyleObject = {
    width: ['auto', '38%', '34%'],
    marginTop: fluidScale(12, 1.5, 6.5),
    marginBottom: [5, 17],
    marginRight: [3, 16],
    marginLeft: [3, 0],
    zIndex: 1,
  }

  const textFallback: ThemeUIStyleObject = {
    'marginTop ': 20,
  }

  const mobileFlag: ThemeUIStyleObject = {
    display: ['block', 'none'],
    marginX: '-25%',
  }

  const desktopFlag: ThemeUIStyleObject = {
    display: ['none', 'block'],
    flexGrow: 1,
    alignSelf: 'stretch',
    marginX: [null, '-12%', '-5%'],
    marginTop: '-2.2rem',
    zIndex: 1,
  }

  const whichImage: ThemeUIStyleObject = {
    marginLeft: [8, 0],
    marginRight: [8, 19],
    marginBottom: 12,
  }

  const imgProps = {
    image: data.ecoFlag.childImageSharp.gatsbyImageData,
    backgroundColor: 'e7eaea',
    imgStyle: { objectPosition: 'left' },
    alt: 'Union Jack in Ecotricity green with people vs. fossil fuels',
  }

  const whichImgProps = {
    image: data.whichLogos.gatsbyImageData,
    backgroundColor: 'e7eaea',
    imgStyle: { objectPosition: 'left' },
    alt: 'Which recommended energy provider and which eco provider logos',
  }

  //Experiment & Variants used for A/B testing
  return (
    <Flex sx={wrapper}>
      <Box sx={{ ...textFallback, ...text }}>
        <GatsbyImage sx={whichImage} {...whichImgProps} />
        <HomeHeroText heading={heading} subheading={subheading} />
        <HomeHeroWidget callToAction={callToAction} />
        <GatsbyImage sx={mobileFlag} {...imgProps} />
      </Box>
      <GatsbyImage sx={desktopFlag} {...imgProps} loading="eager" />
    </Flex>
  )
}

export default HomeHero
