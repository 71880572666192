import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import BusinessHero from '../components/business-hero'
import Spread from '../components/spread'
import Strapline from '../components/strapline'
import JoinUs from '../components/join-us'
import Cluster from '../components/cluster'
import GeneralContent from '../components/general-content'

export const query = graphql`
  query Business(
    $pageId: String!
    $pageSlug: String!
    $relatedPages: Boolean!
    $relatedFilter: ContentfulPageFilterInput
    $relatedLimit: Int
  ) {
    business: contentfulIndex(id: { eq: $pageId }) {
      title
      slug
      description
      sections {
        __typename
        ... on Node {
          ... on ContentfulBusinessHero {
            servicesHeading
            servicesLinks {
              title
              body {
                raw
              }
            }
            actionsHeading
            actionsLinks {
              title
              body {
                raw
              }
            }
          }
          ... on ContentfulStrapline {
            strapline
          }
          ... on ContentfulItem {
            ...Item
          }
          ... on ContentfulGeneralContent {
            title
            type
            fill
            items {
              ... on Node {
                ... on ContentfulFaq {
                  id
                  question
                  __typename
                  anchor
                  answer {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulGuide {
                  id
                  heading
                  anchor
                  body {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulItem {
                  itemTitle: title
                  image {
                    gatsbyImageData(
                      quality: 60
                      layout: FULL_WIDTH
                      formats: [WEBP]
                    )
                  }
                  video {
                    title
                    url
                  }
                  body {
                    raw
                    references {
                      ...References
                    }
                  }
                }
                ... on ContentfulClient {
                  name
                  logo {
                    gatsbyImageData(
                      width: 170
                      quality: 60
                      layout: FIXED
                      formats: [WEBP]
                    )
                  }
                  page {
                    slug
                    topic {
                      slug
                      context {
                        slug
                      }
                    }
                  }
                }
                ... on ContentfulSupportCategory {
                  slug
                  name
                  icon {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulRelatedPages {
            __typename
            title
            pages {
              ...RelatedPage
            }
          }
          ... on ContentfulJoinUs {
            heading
            callToAction
          }
        }
      }
    }
    topic: contentfulTopic(slug: { eq: $pageSlug }) {
      name
      heading
      subheading
      banner {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
      }
    }
    related: allContentfulPage(
      filter: $relatedFilter
      sort: { createdAtTimestamp: DESC }
      limit: $relatedLimit
    ) @include(if: $relatedPages) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const Business: React.FC<PageProps<GatsbyTypes.BusinessQuery>> = ({
  data: { business = {}, topic = {}, related = {} },
  location,
}) => {
  const sections = business.sections
    ?.map((section, i) => {
      switch (section?.__typename) {
        case 'ContentfulBusinessHero':
          return (
            <Section as="div" key={i} pos={i} variant="wide">
              <BusinessHero
                servicesHeading={section.servicesHeading as string}
                servicesLinks={section.servicesLinks?.map(links => ({
                  title: links?.title,
                  body: links?.body,
                }))}
                actionsHeading={section.actionsHeading as string}
                actionsLinks={section.actionsLinks?.map(links => ({
                  title: links?.title,
                  body: links?.body,
                }))}
              />
            </Section>
          )

        case 'ContentfulStrapline':
          return (
            <Section as="div" key={i} pos={i} fill="sprayNESW" variant="wide">
              <Strapline strapline={section.strapline as string} />
            </Section>
          )

        case 'ContentfulItem':
          return (
            <Section key={i} pos={i} variant="index">
              <Spread
                title={section.title}
                items={[
                  {
                    body: section.body,
                    image: section.image,
                    video: section.video,
                  },
                ]}
                flip={Boolean(i & 1)}
                variant="index"
              />
            </Section>
          )

        case 'ContentfulGeneralContent': {
          const params =
            section.type[0] === 'Cluster'
              ? {
                  cols: Math.min(Math.max(section.items.length, 2), 4) as
                    | 2
                    | 3
                    | 4,
                  variant: 'support',
                }
              : section.type[0] === 'Quick Links'
              ? { maxLinkCount: i === 0 ? 3 : undefined }
              : section.type[0] === 'Support'
              ? { location, headingAs: 'h2', headingVariant: 'lrg' }
              : {}

          return (
            <Section key={i} pos={i} fill={section.fill} variant="index">
              <GeneralContent {...section} params={params} />
            </Section>
          )
        }
        case 'ContentfulRelatedPages': {
          const pinned = section.pages || []
          const nodes = related.nodes || []
          return (
            <Section key={i} pos={i} variant="index">
              <Cluster
                title={section.title}
                items={[...pinned, ...nodes]?.map(page => ({
                  title: page?.title,
                  image: page?.image,
                  text: page?.description,
                  page: page,
                }))}
                variant="index"
              />
            </Section>
          )
        }

        case 'ContentfulJoinUs':
          return (
            <Section key={i} pos={i} variant="index">
              <JoinUs
                heading={section.heading}
                callToAction={section.callToAction}
              />
            </Section>
          )
        default:
          return null
      }
    })

  return (
    <Layout page={{ ...business, topic }} masthead="business">
      {sections}
    </Layout>
  )
}

export default Business
