import React from 'react'
import { navigate } from 'gatsby'
import { Box, Button, Text } from 'theme-ui'
import { useState, useEffect, useRef } from 'react'
import { submitForm } from './utils/submit'
import { SubmitHandler, UseFormMethods } from 'react-hook-form'

const WebForm = <
  SubmitType,
  FormType extends Record<string, unknown> = Record<string, unknown>
>({
  reformat,
  handleSubmit,
  completeText,
  children,
  postSubmitUrl,
}: {
  reformat: (x: FormType) => SubmitType
  completeText: React.ReactNode | string
  postSubmitUrl?: string
  children: React.ReactNode
} & UseFormMethods<FormType>): JSX.Element => {
  const [state, setState] = useState<{
    readonly submissionError: string | null
    readonly inFlight: boolean
    readonly submitted: boolean
  }>({
    submissionError: null,
    inFlight: false,
    submitted: false,
  })

  const resetForm = () => {
    setState({
      submissionError: null,
      inFlight: false,
      submitted: false,
    })
  }

  const onSubmit: SubmitHandler<FormType> = formData => {
    if (state.inFlight) {
      return
    }

    setState({ ...state, inFlight: true, submissionError: null })

    submitForm(reformat(formData))
      .then(() => setState({ ...state, inFlight: false, submitted: true }))
      .then(() => {
        if (postSubmitUrl) {
          navigate(postSubmitUrl)
        }
      })
      .catch(() =>
        setState({
          ...state,
          submissionError: 'Submission failed!',
          inFlight: false,
        })
      )
  }

  const success = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (state.submitted && success.current) {
      success.current.scrollIntoView(false)
    }
  }, [state.submitted])

  return state.submitted ? (
    <Box ref={success}>
      <Text>{completeText}</Text>
      <Button onClick={resetForm} variant="secondary">
        Reset form
      </Button>
    </Box>
  ) : (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {children}

      <Button type="submit">
        {state.inFlight ? 'Submitting form...' : 'Submit form'}
      </Button>
      {state.submissionError === null ? (
        ''
      ) : (
        <Text as="p" variant="errorLabel">
          {state.submissionError}
        </Text>
      )}
    </form>
  )
}

export default WebForm
