import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import NewsFilters from '../components/news-filters'
import Cluster from '../components/cluster'

export const query = graphql`
  query News(
    $pageId: String!
    $pageSlug: String!
    $noFilter: Boolean!
    $filterByTag: Boolean!
    $filterByYear: Boolean!
    $filterTag: String
    $filterStart: Float
    $filterEnd: Float
  ) {
    news: contentfulIndex(id: { eq: $pageId }) {
      title
      description
    }
    topic: contentfulTopic(slug: { eq: $pageSlug }) {
      name
      heading
      subheading
      banner {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
      }
    }
    allPosts: allContentfulPage(
      filter: { topic: { slug: { eq: "our-news" } } }
      sort: { createdAtTimestamp: DESC }
    ) @include(if: $noFilter) {
      edges {
        node {
          ...RelatedPage
        }
      }
    }
    postsByTag: allContentfulPage(
      filter: {
        topic: { slug: { eq: "our-news" } }
        tags: { elemMatch: { slug: { eq: $filterTag } } }
      }
      sort: { createdAtTimestamp: DESC }
    ) @include(if: $filterByTag) {
      edges {
        node {
          ...RelatedPage
        }
      }
    }
    postsByYear: allContentfulPage(
      filter: {
        topic: { slug: { eq: "our-news" } }
        createdAtTimestamp: { gte: $filterStart, lt: $filterEnd }
      }
      sort: { createdAtTimestamp: DESC }
    ) @include(if: $filterByYear) {
      edges {
        node {
          ...RelatedPage
        }
      }
    }
  }
`

const News: React.FC<PageProps<GatsbyTypes.NewsQuery>> = ({
  location,
  data: {
    news = {},
    topic = {},
    allPosts = {},
    postsByTag = {},
    postsByYear = {},
  },
}) => {
  const posts = allPosts.edges || postsByTag.edges || postsByYear.edges || []

  return (
    <Layout page={{ ...news, topic }} masthead="news">
      <Section as="div" variant="header">
        <NewsFilters path={location.pathname} />
      </Section>
      <Section variant="page">
        <Cluster
          items={posts.map(({ node: post }) => ({
            title: post.title,
            date: post.createdAtTimestamp
              ? new Date(post.createdAtTimestamp)
              : undefined,
            tags: post.tags?.map(tag => tag?.name),
            image: post.image,
            text: post.description,
            page: post,
          }))}
          limit={12}
          more={true}
          variant="full"
          cols={4}
        />
      </Section>
    </Layout>
  )
}

export default News
