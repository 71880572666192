import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import Spread from '../components/spread'

export const query = graphql`
  query LandingPage($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      slug
      topic {
        name
        slug
      }
      tags {
        name
        slug
      }
      heading
      subheading
      divisions
      hideFromSearchEngines
      sections {
        __typename
        ... on ContentfulItem {
          ...Item
        }
      }
    }
  }
`

const Page: React.FC<PageProps<GatsbyTypes.LandingPageQuery>> = ({
  data: { page = {} },
}) => {
  const sections = page.sections
    ?.map(section => {
      switch (section?.__typename) {
        case 'ContentfulItem':
          return (
            <Spread
              title={section.title}
              items={[
                {
                  body: section.body,
                  image: section.image,
                  video: section.video,
                },
              ]}
              flip={true}
              centered={true}
              variant="index"
            />
          )

        default:
          return null
      }
    })
    .map((section, i: number) => (
      <Section
        as="div"
        key={i}
        pos={i}
        count={page.sections?.length}
        variant="loose"
      >
        {section}
      </Section>
    ))

  return (
    <Layout page={page} masthead="breadcrumb">
      {sections}
    </Layout>
  )
}

export default Page
