import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import ClientHeader from '../components/client-header'
import Article from '../components/article'
import Hero from '../components/hero'
import Cluster from '../components/cluster'

export const query = graphql`
  query CaseStudy($pageId: String!) {
    client: contentfulPage(id: { eq: $pageId }) {
      title
      slug
      description
      topic {
        name
        slug
        heading
        context {
          name
          slug
          heading
        }
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      sections {
        __typename
        ... on ContentfulClient {
          body {
            raw
            references {
              ...ArticleReferences
            }
          }
          aside {
            raw
            references {
              ...ArticleReferences
            }
          }
        }
        ... on ContentfulHero {
          image {
            title
            gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
          }
          video {
            title
            url
          }
        }
      }
    }
    related: allContentfulPage(
      filter: { topic: { slug: { eq: "case-studies" } } }
      sort: { createdAtTimestamp: DESC }
      limit: 3
    ) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const CaseStudy: React.FC<PageProps<GatsbyTypes.CaseStudyQuery>> = ({
  data: { client = {}, related = {} },
}) => {
  const article = client.sections?.find(
    section => section?.__typename === 'ContentfulClient'
  ) as GatsbyTypes.ContentfulClient | undefined

  const hero = client.sections?.find(
    section => section?.__typename === 'ContentfulHero'
  ) as GatsbyTypes.ContentfulHero | undefined

  return (
    <Layout page={client} masthead="breadcrumb">
      <Section as="div" variant={article ? 'header' : 'page'}>
        <ClientHeader
          topic={client.topic?.context?.name}
          heading={client.heading || client.title}
        />
      </Section>
      {article && (
        <Section pos={0} count={hero ? 2 : 1} variant="page">
          <Article body={article.body} aside={article.aside} variant="client" />
        </Section>
      )}
      {hero && (
        <Section pos={article ? 1 : 0} count={article ? 2 : 1} variant="page">
          <Hero image={hero.image} video={hero.video} />
        </Section>
      )}
      <Section variant="page">
        <Cluster
          title="Similar case studies"
          items={related.nodes?.map(page => ({
            title: page.title,
            image: page.image,
            text: page.description,
            page: page,
          }))}
        />
      </Section>
    </Layout>
  )
}

export default CaseStudy
