import React from 'react'
import { Box, Input, Text, Label } from 'theme-ui'
import { UseFormMethods, FieldError } from 'react-hook-form'

import parseISO from 'date-fns/fp/parseISO'
import getDaysInMonth from 'date-fns/fp/getDaysInMonth'

const isoDateDayRegex = /^(\d{4})-(\d{2})-(\d{2})$/

const validate = (val: unknown): string | boolean => {
  if (typeof val === 'string') {
    const str = val.trim()

    const results = str.match(isoDateDayRegex)
    if (results) {
      const [, yearStr, monthStr, dayStr] = results

      const month = Number(monthStr)
      const day = Number(dayStr)

      const daysInMonth = getDaysInMonth(parseISO(`${yearStr}-${monthStr}`))

      return month >= 1 && month <= 12
        ? day >= 1 && day <= daysInMonth
          ? true
          : `day must be 1 - ${daysInMonth}`
        : 'month must be 1 - 12'
    }
  }
  return 'Date must be yyyy-mm-dd'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFieldError = (x: any | undefined): x is FieldError =>
  typeof x?.message === 'string'

const DatePicker = <Name extends string>({
  defaultValue,
  register,
  min,
  max,
  errors,
  errorMessage,
  name,
  required,
  label,
}: UseFormMethods<{ readonly [Key in Name]: string }> & {
  defaultValue?: string
  min?: string
  max?: string
  readonly errorMessage: string
  readonly name: Name
  required?: boolean
  label?: string
}): JSX.Element => {
  const potentialError = errors[name]
  const error = (
    potentialError !== undefined &&
    isFieldError(potentialError) &&
    !Array.isArray(potentialError)
      ? potentialError
      : undefined
  ) as FieldError | undefined

  return (
    <Box sx={{ marginBottom: '1rem' }}>
      {label && <Label htmlFor={name}>{label}:</Label>}
      <Input
        aria-label="Pick date"
        aria-describedby={`${name}-date-error`}
        data-qa="datePicker"
        defaultValue={defaultValue}
        min={min}
        max={max}
        name={name}
        pattern={isoDateDayRegex.source}
        variant="complexFormInput"
        placeholder="yyyy-mm-dd"
        ref={register({
          validate,
          required: required ? errorMessage : required,
        })}
        required={required}
        step="1"
        type="date"
        sx={{ maxWidth: '20rem' }}
      />
      {error?.message && (
        <Text as="p" id={`${name}-date-error`} variant="errorLabel">
          {error.message}
        </Text>
      )}
    </Box>
  )
}

export default DatePicker
