import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import NewsHeader from '../components/news-header'
import Article from '../components/article'
import NewsFilters from '../components/news-filters'
import Cluster from '../components/cluster'

export const query = graphql`
  query NewsPost($pageId: String!) {
    post: contentfulPage(id: { eq: $pageId }) {
      title
      description
      slug
      topic {
        name
        heading
        slug
      }
      tags {
        name
        slug
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      sections {
        __typename
        ... on ContentfulArticle {
          author {
            name
            image {
              gatsbyImageData(
                width: 100
                quality: 60
                layout: FIXED
                formats: [WEBP]
              )
            }
          }
          body {
            raw
            references {
              ...ArticleReferences
            }
          }
        }
      }
      createdAtTimestamp
    }
    related: allContentfulPage(
      filter: { topic: { slug: { eq: "our-news" } } }
      sort: { createdAtTimestamp: DESC }
      limit: 3
    ) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const NewsPost: React.FC<PageProps<GatsbyTypes.NewsPostQuery>> = ({
  data: { post = {}, related = {} },
}) => {
  const article = post.sections?.find(
    section => section?.__typename === 'ContentfulArticle'
  ) as GatsbyTypes.ContentfulArticle | undefined

  const date = post?.createdAtTimestamp
    ? new Date(post.createdAtTimestamp)
    : undefined

  return (
    <Layout page={post} masthead="breadcrumb">
      <Section as="div" variant={article ? 'header' : 'page'}>
        {article && (
          <NewsFilters
            tags={
              post.tags?.map(tag => ({
                name: tag?.name,
                slug: tag?.slug,
              })) || []
            }
            year={date?.getFullYear()}
          />
        )}
        <NewsHeader
          topic={post.topic?.heading || post.topic?.name}
          heading={post.heading || post.title}
          subheading={post.subheading}
          author={article?.author}
          date={date}
        />
      </Section>
      {article && (
        <Section variant="page">
          <Article body={article.body} variant="news" />
        </Section>
      )}
      <Section variant="page">
        <Cluster
          title="Similar articles"
          items={related.nodes?.map(page => ({
            title: page.title,
            image: page.image,
            text: page.description,
            page: page,
          }))}
          variant="index"
        />
      </Section>
    </Layout>
  )
}

export default NewsPost
